import { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

import { Button, Header as MirandaHeader, Dropdown, Text } from '@loadsmart/miranda-react'

import { TOKEN_STORAGE_USER_KEY, LOGIN_STORAGE_USER } from 'auth/domain/User'
import useOauth2Flow from 'auth/ui/hooks/useOauth2'
import { LocalStorageClient } from 'core/infra/storage/LocalStorageClient'
import { signOut } from 'core/utils/signout'

import { StyledHeaderDiv, StyledDropDown, StyledActionsDiv } from './Header.styles'

interface UserLogin {
  client: string
  first_name: string
  last_name: string
  email: string
  issuer: string
}

const Header = () => {
  const navigate = useNavigate()
  const storage = new LocalStorageClient()
  const token = storage.get(TOKEN_STORAGE_USER_KEY)

  const user_login: UserLogin = storage.get(LOGIN_STORAGE_USER) as UserLogin

  const { logOut } = useOauth2Flow()

  const handleSignOut = () => {
    signOut()
    logOut()
  }

  const handleSignIn = () => {
    navigate('/login')
  }

  useEffect(() => {
    const userAgent = window.navigator.userAgent
    const isMobile = /Mobi/i.test(userAgent)

    if (isMobile) {
      navigate('/mobile-not-supported')
    }
  }, [navigate])

  return (
    <StyledHeaderDiv>
      <MirandaHeader>
        <MirandaHeader.Content>
          <MirandaHeader.Title></MirandaHeader.Title>
        </MirandaHeader.Content>
        <MirandaHeader.Actions>
          {token ? (
            <StyledActionsDiv>
              <Text variant="body-sm-bold" color="color-primary-60">
                {user_login.client}
              </Text>
              <StyledDropDown size="small">
                <Dropdown.Trigger aria-setsize={10}>
                  {user_login.first_name} {user_login.last_name}
                </Dropdown.Trigger>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <Button size="small" variant="tertiary" onClick={handleSignOut}>
                      Sign Out
                    </Button>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </StyledDropDown>
            </StyledActionsDiv>
          ) : (
            <Button variant="primary" onClick={handleSignIn}>
              Sign In
            </Button>
          )}
        </MirandaHeader.Actions>
      </MirandaHeader>
    </StyledHeaderDiv>
  )
}

export default Header
