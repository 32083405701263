import { useCallback } from 'react'

import mixpanel from 'mixpanel-browser'

import { MIXPANEL_TOKEN } from 'core/infra/environment'

import { AnalyticsEvent, AnalyticsProperties } from './events'

type AnalyticsUser = {
  email: string
  client: string
  firstName: string
  lastName: string
  issuer: string
}

export function useAnalytics() {
  const init = useCallback(() => {
    mixpanel.init(MIXPANEL_TOKEN)
  }, [])

  const setup = useCallback((user: AnalyticsUser) => {
    mixpanel.register({
      ...user,
      app: 'ls-analytics-web',
    })
    mixpanel.people.set(user)
    mixpanel.identify(user.email)
  }, [])

  const track = useCallback((event: AnalyticsEvent, properties?: AnalyticsProperties) => {
    mixpanel.track(event, properties)
  }, [])

  return { init, setup, track }
}

export default useAnalytics
