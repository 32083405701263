import { useEffect } from 'react'

import { createUserRepository } from 'auth/infra/UserRepository'
import useAnalytics from 'core/analytics/useAnalytics'
import { createTokenInterceptor, HttpClient } from 'core/infra/http/HttpClient'
import { SentryWrapper } from 'core/infra/sentry/SentryWrapper'
import { LocalStorageClient } from 'core/infra/storage/LocalStorageClient'
import { LOGIN_STORAGE_USER } from 'auth/domain/User'

type UserLogin = {
  client: string
  first_name: string
  last_name: string
  email: string
  issuer: string
}

export function useInitApplication() {
  const { init, setup } = useAnalytics()

  const userRepository = createUserRepository({
    storage: new LocalStorageClient(),
  })

  HttpClient.setup({
    request: createTokenInterceptor({ userRepository }),
  })

  SentryWrapper.init()

  useEffect(() => {
    init()
    const storage = new LocalStorageClient()
    const user_login: UserLogin = storage.get(LOGIN_STORAGE_USER) as UserLogin;

    if (user_login) {
      setup({
        email: user_login.email,
        client: user_login.client,
        lastName: user_login.last_name,
        firstName: user_login.first_name,
        issuer: user_login.issuer,
      })
    }
  }, [init, setup])
}
