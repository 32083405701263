export const enum AnalyticsEvent {
  //InitApplication
  InitApplication = 'InitApplication / User initialized the application',

  //Integrations
  OpenIntegrationsOverview = 'Integrations / Open Integrations Overview',

  //Report
  OpenReport = 'Report / Open report',

  //Demo 
  OpenDemo = 'Demo / Open demo',

  //PowerBI Embed events
  PBIEmbedEvent = 'PowerBIEmbedEvent / Power BI  embed event',
}
export type AnalyticsProperties = Record<string, unknown>
